import React from 'react'
import Layout from '../components/App/Layout'
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import setupData from "../../data/setup.json"
import { graphql, Link } from 'gatsby'

export const query = graphql`
    query ($title: String!) {
        setupJson(title: {eq: $title}) {
            protocols {
              incoming {
                incoming_auth_type
                incoming_conn_security
                incoming_host
                incoming_port
                incoming_protocol
                incoming_sid
                incoming_updated_at
              }
              outgoing {
                outgoing_auth_type
                outgoing_conn_security
                outgoing_host
                outgoing_port
                outgoing_protocol
                outgoing_require_login
                outgoing_sid
                outgoing_updated_at
              }
            }
            title
        }
    }
`

const Setup = ({ data }) => {
    
    const content = data.setupJson
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);
    const dataShuffle = shuffle(setupData);
    const currentPage = content.title

    return (
        <Layout>    
        <SEO
            postTitle={content.title + " | BlueMail App"}
            postDescription={"Setup settings for " + content.title}
            postImage= '/img/OG/og_image-default-setup.png' 
            postURL={`setup/${content.title.toLowerCase()}` + "-email-account-settings"}
         />
        <Navbar />
        <div className="setup-main">           
            <div className="container">
                <h2>Set up your <span>{content.title}</span> | The Correct IMAP and SMTP Settings</h2>
                <hr />
                <h4>How to access your <span className='title-p'>{content.title}</span> Account from any Email app using the 
                    right configurations
                </h4>
                <p>Instead of using <span className='title-p'>{content.title}</span>'s webmail interface, you can use mobile and desktop email apps like BlueMail, Outlook, Apple Mail, or Mozilla Thunderbird. Desktop 
                    email apps offer multiple features along with quicker access, and your emails are still available 
                    on your computer or mobile phone even when you're offline.
                </p>
                <h4>Setup Your <span className='title-p'>{content.title}</span> Account with Your Email app Using IMAP:</h4>
                <p>To access your <span className='title-p'>{content.title}</span> account from a desktop email app or a mobile 
                    email app, you will need to configure the IMAP or POP3 and SMTP settings to be as the below:
                </p>
                <div>
                <div className="setup-table-inside">
                    {content.protocols.incoming
                        .map((item,key) => {
                            if(item.incoming_protocol.includes("imap")) {
                                if(item.incoming_conn_security === "SSL_TLS_REQUIRED" || item.incoming_conn_security === "SSL_TLS_OPTIONAL") {
                                    item.incoming_conn_security = "SSL/TLS"
                                } else if(item.incoming_conn_security === "STARTTLS_REQUIRED" || item.incoming_conn_security === "STARTTLS_REQUIRED") {
                                    item.incoming_conn_security = "STARTTLS"
                                }
                                return (
                                    <table className="setup-table"> 
                                        <thead>                            
                                            <tbody>
                                                <h4>{item.incoming_protocol.toUpperCase()}</h4>
                                                <tr key={key} style={{backgroundColor: "#E8E8E8"}} colspan="2"><h6>{content.title} - Incoming ({item.incoming_protocol.toUpperCase()})</h6>
                                                    <tr>
                                                        <td>Server:</td>
                                                        <td>{item.incoming_host}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Port:</td>
                                                        <td>{item.incoming_port}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Security:</td> 
                                                        <td>{item.incoming_conn_security}</td>
                                                    </tr>    
                                                    <tr>
                                                        <td>Username:</td> 
                                                        <td>Your email address</td>
                                                    </tr> 
                                                    <tr>
                                                        <td>Password:</td> 
                                                        <td>Your {content.title} password</td>
                                                    </tr>                                              
                                                </tr>
                                            </tbody>
                                        </thead>  
                                    </table>                                     
                                )
                            }
                        })}
                    </div>
                    <div className="setup-table-inside">
                        {content.protocols.outgoing
                            .map((item,key) => {
                                if(item.outgoing_protocol.includes("smtp")) {
                                    if(item.outgoing_conn_security === "SSL_TLS_REQUIRED" || item.outgoing_conn_security === "SSL_TLS_OPTIONAL") {
                                        item.outgoing_conn_security = "SSL/TLS"
                                    } else if(item.outgoing_conn_security === "STARTTLS_REQUIRED" || item.outgoing_conn_security === "STARTTLS_OPTIONAL") {
                                        item.outgoing_conn_security = "STARTTLS"
                                    }
                                    return (
                                        <table className="setup-table"> 
                                            <thead>                            
                                                <tbody>
                                                <h4>&nbsp;</h4>
                                                <tr key={key} style={{backgroundColor: "#E8E8E8"}} colspan="2"><h6>{content.title} - Outgoing ({item.outgoing_protocol.toUpperCase()})</h6>
                                                        <tr>
                                                            <td>Server:</td>
                                                            <td>{item.outgoing_host}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Port:</td>
                                                            <td>{item.outgoing_port}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Security:</td> 
                                                            <td>{item.outgoing_conn_security}</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Username:</td> 
                                                            <td>Your email address</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Password:</td> 
                                                            <td>Your {content.title} password</td>
                                                        </tr>                                              
                                                    </tr>
                                                </tbody>
                                            </thead>  
                                        </table>                                     
                                    )
                                }
                            })
                        }
                    </div>
                    <div className="setup-table-inside">
                        {content.protocols.incoming
                            .map((item,key) => {
                                if(item.incoming_protocol.includes("pop")) {
                                    if(item.incoming_conn_security === "SSL_TLS_REQUIRED" || item.incoming_conn_security === "SSL_TLS_OPTIONAL") {
                                        item.incoming_conn_security = "SSL/TLS"
                                    } else if(item.incoming_conn_security === "STARTTLS_REQUIRED" || item.incoming_conn_security === "STARTTLS_REQUIRED") {
                                        item.incoming_conn_security = "STARTTLS"
                                    }
                                    return (
                                        <table className="setup-table"> 
                                            <thead>                            
                                                <tbody>
                                                    <h4>{item.incoming_protocol.toUpperCase()}</h4>
                                                    <tr key={key} style={{backgroundColor: "#E8E8E8"}} colspan="2"><h6>{content.title} - Incoming ({item.incoming_protocol.toUpperCase()})</h6>
                                                        <tr>
                                                            <td>Server:</td>
                                                            <td>{item.incoming_host}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Port:</td>
                                                            <td>{item.incoming_port}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Security:</td> 
                                                            <td>{item.incoming_conn_security}</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Username:</td> 
                                                            <td>Your email address</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Password:</td> 
                                                            <td>Your {content.title} password</td>
                                                        </tr>                                              
                                                    </tr>
                                                </tbody>
                                            </thead>  
                                        </table>                                     
                                    )
                                }
                            })
                        }
                    </div>
                    <div className="setup-table-inside">
                        {content.protocols.outgoing
                            .map((item,key) => {
                                if(item.outgoing_protocol.includes("smtp")) {
                                    if(item.outgoing_conn_security === "SSL_TLS_REQUIRED" || item.outgoing_conn_security === "SSL_TLS_OPTIONAL") {
                                        item.outgoing_conn_security = "SSL/TLS"
                                    } else if(item.outgoing_conn_security === "STARTTLS_REQUIRED" || item.outgoing_conn_security === "STARTTLS_OPTIONAL") {
                                        item.outgoing_conn_security = "STARTTLS"
                                    }
                                    return (
                                        <table className="setup-table"> 
                                            <thead>                            
                                                <tbody>
                                                <h4>&nbsp;</h4>
                                                <tr key={key} style={{backgroundColor: "#E8E8E8"}} colspan="2"><h6>{content.title} - Outgoing ({item.outgoing_protocol.toUpperCase()})</h6>
                                                        <tr>
                                                            <td>Server:</td>
                                                            <td>{item.outgoing_host}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Port:</td>
                                                            <td>{item.outgoing_port}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Security:</td> 
                                                            <td>{item.outgoing_conn_security}</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Username:</td> 
                                                            <td>Your email address</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Password:</td> 
                                                            <td>Your {content.title} password</td>
                                                        </tr>                                              
                                                    </tr>
                                                </tbody>
                                            </thead>  
                                        </table>                                     
                                    )
                                }
                            })
                        }
                    </div>
                    {/* EWS sections have been commented out */}
                </div>
            </div>
        </div>
        <div className='container pb-120'>
            <div className='row'>
                <div className='col-lg-3 col-md-6 col-xs-12 mt-3'>
                    <h5>To setup additional providers:</h5>
                </div>
                <div className='col-lg-9 col-md-6 col-xs-12 d-flex justify-content-center align-items-center providers-box mt-3'>
                    {dataShuffle.slice(0, 3).map((data, index) => {                            
                        return (
                            <div className='content'>
                                {data.title !== currentPage && <div className='col-lg-3 col-md-3 col-xs-12 text-center' key={index}>
                                    <Link className='other-providers' style={{fontWeight: "700"}} to={`/setup/${data.title.toLowerCase().replace(".", "-")}` + "-email-account-settings"}>{data.title}</Link>
                                </div>
                                }
                            </div>
                        ) 
                    })} 
                </div>
                
            </div>
        </div>
        <Footer />
        </Layout>
    )
}

export default Setup
